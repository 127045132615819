//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

//Components
import Layout from '../components/layout'

//Packages
import moment from 'moment'
import Helmet from 'react-helmet'

//Icons
import {
  BsCheckCircle,
  BsExclamationCircle,
  BsCloudMinus,
  BsXCircleFill,
} from 'react-icons/bs'
import { FaWrench } from 'react-icons/fa'

//Assets

//Status options
// ok, degraded, impacted, outage, maintain
const serviceStatus = [
  {
    name: 'Console',
    status: 'ok',
    // comment: 'The console service has been impacted.',
    // datecreated: 1638219911,
  },
  { name: 'Account services', status: 'ok', comment: '' },
  { name: 'Minting', status: 'ok', comment: '' },
  { name: 'DPP Planner', status: 'ok', comment: '' },
  { name: 'Loyalty', status: 'ok', comment: '' },
  { name: 'Communities', status: 'ok', comment: '' },
  { name: 'Marketplaces', status: 'ok', comment: '' },
  { name: 'DPP Data Events', status: 'ok', comment: '' },
  { name: 'Token Collections', status: 'ok', comment: '' },
  { name: 'Token Explorer', status: 'ok', comment: '' },
  { name: 'Onboarding Pages', status: 'ok', comment: '' },
  { name: 'Support services', status: 'ok', comment: '' },
]
const Snippet = () => {
  return (
    <Layout>
      <Helmet
        title="PicoNext - Service Status"
        meta={[
          {
            name: 'description',
            content: 'See current PicoNext service status',
          },
        ]}
      />
      <div class="container container-status p-4">
        <div class="row">
          <div class="col-md-12">
            <h2>PicoNext Service Status</h2>
          </div>
        </div>
        <div
          class={`row my-3 mx-1 pt-3 pb-2 round-corners ${
            serviceStatus.every((service) => service.status === 'ok')
              ? 'border-1-dkgreen-1 dkgreen-1'
              : 'border-1-yellow-1 yellow-1'
          }`}
        >
          <div class="col-md-12">
            <h4>
              {serviceStatus.every((service) => service.status === 'ok')
                ? 'All systems operational'
                : 'Some services impacted'}
            </h4>
          </div>
        </div>
        <hr size={1} />
        {serviceStatus.map((service, i) => (
          <div class={`row mx-1 my-2 py-2 ${i % 2 !== 0 ? 'bg-gray-7' : ''}`}>
            <div class="col-md-1 d-flex flex-row align-items-start justify-content-start">
              {service.status === 'ok' ? (
                <BsCheckCircle size={32} class="dkgreen-1" />
              ) : null}
              {service.status === 'degraded' ? (
                <BsCloudMinus size={32} class="yellow-1" />
              ) : null}
              {service.status === 'impacted' ? (
                <BsExclamationCircle size={32} class="orange-1" />
              ) : null}
              {service.status === 'outage' ? (
                <BsXCircleFill size={32} class="dkorange-1" />
              ) : null}
              {service.status === 'maintain' ? (
                <FaWrench size={32} class="bluegreen-1" />
              ) : null}
            </div>
            <div class="col-md-11 h5 line-height-3 d-flex flex-column align-items-start justify-content-center">
              {service.name}
              {service.status !== 'ok' && service.comment ? (
                <p class="service-comment">
                  {service.comment} Started:{' '}
                  {moment
                    .unix(service.datecreated)
                    .format('MMM. D, YYYY h:mm a')}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Snippet
